/*globals $ */

class BackgroundContent {
  constructor($el) {
    this.$el = $el;
    this.id = this.$el.find('video').attr('id');
    this.movie = this.$el.find('video')[0];
    /*
    this.movie.onloadeddata = () => {
      console.log('load complete movie => ' + this.id);
    };
    this.movie.oncanplay = () => {
      console.log('movie oncanplay => ' + this.id);
    };
    */

    this.movie.pause();
  }

  fadein(isFirstView = false) {
    if(isFirstView) {
      this.movie.play();
      // this.$el.stop(false).css({'z-index': 2, 'width':0}).delay(10).animate({'width': '100%'}, 600, 'easeOutExpo');
      this.$el
        .stop(false)
        .css({'opacity':0, 'z-index':2, 'width': '100%'})
        .delay(10)
        .animate({'opacity':1}, 1000, 'easeInOutSine', () => {
          $('body').addClass('onCompleteBackgroundFadein');
        });
    } else {
      clearInterval(this.fadeoutInterval);
      this.movie.play();
      this.$el.stop(false).css({'z-index': 2, 'width':0, 'opacity':1}).delay(30).animate({'width': '100%'}, 900, 'easeInOutExpo');

      // this.$el.stop(false).css({'z-index': 2, 'width':0, 'opacity':1}).delay(30).animate({'width': '100%'}, 1600, 'easeInOutExpo');
    }
  }
  fadeout() {
    /*
    this.$el.stop(false).animate({'opacity': 0}, 600, 'easeOutExpo', () => {
      this.movie.pause();
    });
    */
    this.$el.stop(false).css('z-index', 1);
    this.fadeoutInterval = setInterval(() => {
      clearInterval(this.fadeoutInterval);
      this.movie.pause();
    }, 600);
  }
}

class Background {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.$container = $('.Background');
    this.$window        = $(window);
    this.movieRatio = 16 / 9;

    if(this.mainApp.viewMode == 'sp') {
      this.movieRatio = 9 / 16;
    }

    this.movieTop = 0;
    this.movieLeft = 0;
    this.movieWidth = 0;
    this.movieHeight = 0;

    this.bg_caviar = new BackgroundContent($('.BackgroundContent--left'));
    this.bg_sturgeon = new BackgroundContent($('.BackgroundContent--right'));
    // this.bg_caviar.fadein(true);
    this.currentView = 'left';

    $('.Header--left').mouseover(() => {
      this.switch('left');
    });
    $('.Header--right').mouseover(() => {
      this.switch('right');
    });

    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      });
    this.onWindowResize();
  }

  init(target) {
    if(target == 'caviar') {
      this.bg_caviar.fadein(true);
    } else if(target == 'sturgeon') {
      this.currentView = 'right';
      this.bg_sturgeon.fadein(true);
    }

  }

  switch(target) {
    if(target != this.currentView) {

      this.currentView = target;

      if(this.currentView == 'left') {
        $('.Header--left').addClass('Header--current');
        $('.Header--right').removeClass('Header--current');
        this.bg_caviar.fadein();
        this.bg_sturgeon.fadeout();
      } else if(this.currentView == 'right') {
        $('.Header--left').removeClass('Header--current');
        $('.Header--right').addClass('Header--current');
        this.bg_caviar.fadeout();
        this.bg_sturgeon.fadein();
      }
    }
  }

  onWindowResize() {
    let windowWidth = $('.BackgroundContent__inner').width();//$(document).width();//this.$window.width();
    let windowHeight = this.$window.height();
    if(this.mainApp.viewMode == 'sp') {
      windowHeight = window.innerHeight;
    }
    let windowRatio = windowWidth / windowHeight;


    if(this.movieRatio > windowRatio) {
      this.movieHeight = windowHeight;
      this.movieWidth = this.movieHeight * this.movieRatio;
      this.movieTop = 0;
      this.movieLeft = (windowWidth - this.movieWidth) / 2;
    } else {
      this.movieWidth = windowWidth;
      this.movieHeight = this.movieWidth / this.movieRatio;
      this.movieLeft = 0;
      this.movieTop = (windowHeight - this.movieHeight) / 2;
    }

    $('.BackgroundContent__movie').css({
      'top': this.movieTop,
      'left': this.movieLeft,
      'width': this.movieWidth,
      'height': this.movieHeight
    });
  }
}

export default Background;
