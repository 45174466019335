/*globals $ */

class Works {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.$window        = $(window);

    this.tagBtns = [];
    $('.Tag').each((i, e) => {
      this.tagBtns.push($(e));
    });

    $('.Tag').on('click', (e) => {
      $(e.currentTarget).addClass('Tag--current');
      let targetTag = $(e.currentTarget).attr('data-slug');
      this.filter(targetTag);

      for(let i = 0; i < this.tagBtns.length; i++) {
        if(this.tagBtns[i].attr('data-slug') != targetTag) {
          this.tagBtns[i].removeClass('Tag--current');
        }
      }
    });

    this.isLoadingWorksData = false;
    $('.WorksGridItem:not(.WorksGridItem--blank)').on('click', (e) => {
      if(!this.isLoadingWorksData) {
        this.isLoadingWorksData = true;
        this.getWorksData($(e.currentTarget).attr('data-id'));
      }

      // console.log($(e.currentTarget).attr('data-id'));
    });

    $('.Modal__bg').on('click', () => {
      this.close();
    });
    $('.BtnCloseModal').on('click', () => {
      this.close();
    });

    this.isOpenModal = false;

    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      });
    // this.onWindowResize();
  }

  close() {
    this.isLoadingWorksData = false;
    this.isOpenModal = false;
    $('.Modal').stop(false).animate({'opacity':0}, 400, 'easeOutExpo', () => {
      $('body').css('overflow-y', 'scroll');
      $('.Modal').css('display', 'none');

      $('.ModalContent__title').empty();
      $('.ModalContent__body').empty();
    });
  }

  onWindowResize() {
    if(this.isOpenModal) {
      if(window.innerHeight < $('.ModalContent').outerHeight(true)) {
        $('.ModalWidth__inner').addClass('ModalWidth__inner--scroll');
      } else {
        $('.ModalWidth__inner').removeClass('ModalWidth__inner--scroll');
      }
    }
  }

  getWorksData(id) {
    // console.log('getWorksData : ' + id);
    $('body').css('overflow-y', 'hidden');
    // $('.Modal').css({'opacity':0, 'display':'block'});

    $('.ModalContent__title').empty();
    $('.ModalContent__body').empty();

    $('.ModalContent, .BtnCloseModal').css('opacity', 0);

    $('.Loader').stop(false).css('opacity', 1);

    if($('html').hasClass('ua-mobile')) {
      $('.Modal').css('height', window.innerHeight);
    }

    $('.Modal').stop(false).css({'opacity':0, 'display':'block'}).delay(10).animate({'opacity':1}, 400, 'easeOutExpo');


    $.ajax('/works/' + id, {
      'timeout'   : 5000,
      'datatype'  : 'html',
      'xhrFields' : {
        'withCredentials' :true
      },
    }).then((data) => {
      this.isOpenModal = true;
      let $html = $($.parseHTML(data));

      $('.ModalContent__title').text($($html.filter('h1')[0]).text());
      $('.ModalContent__body').html($html.filter('.SingleContent')[0]);


      setTimeout(() => {
        if(window.innerHeight < $('.ModalContent').outerHeight(true)) {
          $('.ModalWidth__inner').addClass('ModalWidth__inner--scroll');
        } else {
          $('.ModalWidth__inner').removeClass('ModalWidth__inner--scroll');
        }

        // $('.Modal').css('opacity', 1);
        $('.ModalContent').animate({'opacity':1}, 400, 'easeOutExpo', () => {
          $('.BtnCloseModal').animate({'opacity':1}, 400, 'easeOutExpo');
        });

        $('.Loader').stop(false).animate({'opacity': 0}, 400, 'easeOutExpo');

      }, 300);



      this.afterProcess();


    }, (jqXHR, status) => {
      console.log(jqXHR);
      console.log(status);
    });
  }

  afterProcess() {

    $('.SingleContent').find('iframe').each((i, e) => {
      // if($(e).attr('src').indexOf('youtube') != -1) {
        let iframe = $(e);
        let ytContainer = $('<span class="embed-container"></span>');
        $(e).replaceWith(ytContainer);
        ytContainer.append(iframe);
      // }
    });

    if($('.SingleContent').find('.works-contact').length) {
      $('.SingleContent').find('.works-contact').each((i, e) => {
        let $target = $(e);
        let mail = '';
        if($('body').hasClass('side-caviar')) {
          mail = 'meetme@caviar.ws';
        } else if($('body').hasClass('side-sturgeon')) {
          mail = 'meetme@sturgeon.ws';
        }

        // let mail = 'meetme@caviar.ws';
        $target.replaceWith('<p>Please contact ' + `<a href="mailto:${mail}">${mail}</a>` + ' for the password. For business use only.</p>');
      });
    }

    $('.SingleContent').find('a').each((i, e) => {
      let _text = '<a href="' + $(e).attr('href') + '"><span>' + $(e).text() + '</span></a>';
      if($(e).attr('target') != undefined) {
        _text = '<a href="' + $(e).attr('href') + '" target="' + $(e).attr('target') + '"><span>' + $(e).text() + '</span></a>';
      }

      $(e).replaceWith(_text);
    });
  }

  filter(target) {
    let $container = $('.WorksGrid--pc');
    let is_sp = false;
    if($container.parent().css('display') == 'none') {
      is_sp = true;
      $container = $('.WorksGrid--sp');
    }

    $('.WorksGrid').stop(false).css('opacity', 0);
    let addCount = 0;
    if(target == 'all') {
      if(is_sp) {
        $('.WorksGridItem').css({
          'display': 'block',
          'margin-left': 0,
          'margin-right': 0
        });

        $container.find('.WorksGridItem:not(.WorksGridItem--blank)').each((i, e) => {
          $(e).find('.WorksGridItemInfo').removeClass('WorksGridItemInfo--sp-right');
          $(e).find('.WorksGridItemInfo').removeClass('WorksGridItemInfo--sp-left');
        });
      } else {
        $('.WorksGridItem').css({
          'display': 'block'
        });
      }

    } else {
      $container.find('.WorksGridItem:not(.WorksGridItem--blank)').each((i, e) => {
        let id = $(e).attr('data-id');
        let tags = $(e).attr('data-category');

        if(tags.indexOf(target) != -1) {
          $(e).css('display', 'block');

          if(is_sp) {
            if($('.WorksGridItem--for-' + id).length) {
              // blankがある場合は全て非表示
              $('.WorksGridItem--for-' + id).css('display', 'none');
            }
            if(addCount % 2 == 0) {
              $(e).css({
                'margin-left': 0,
                'margin-right': '50%'
              });
              $(e).find('.WorksGridItemInfo').addClass('WorksGridItemInfo--sp-right');
              $(e).find('.WorksGridItemInfo').removeClass('WorksGridItemInfo--sp-left');
            } else if(addCount % 2 == 1) {
              $(e).css({
                'margin-left': '50%',
                'margin-right': 0
              });
              $(e).find('.WorksGridItemInfo').addClass('WorksGridItemInfo--sp-left');
              $(e).find('.WorksGridItemInfo').removeClass('WorksGridItemInfo--sp-right');
            }
            // console.log('%', addCount % 2);
          } else {
            if(addCount == 0) {
              if($('.WorksGridItem--for-' + id).length) {
                $('.WorksGridItem--for-' + id).css('display', 'none');
              }
            } else {
              if($('.WorksGridItem--for-' + id).length) {
                $('.WorksGridItem--for-' + id).css('display', 'block');
              }
            }
          }
          addCount++;

        } else {
          $(e).css('display', 'none');
          if($('.WorksGridItem--for-' + id).length) {
            $('.WorksGridItem--for-' + id).css('display', 'none');
          }
        }
      });
    }

    $('.WorksGrid').animate({'opacity':1}, 500, 'easeOutSine');

    setTimeout(() => {
      this.mainApp.onWindowResize();
    }, 300);

  }
}
export default Works;
