/*globals $ */

class WorksGridItemPc {
  constructor($el) {
    this.$el = $el;
    this.isHover = false;

    this.$el.mouseover((e) => {
      $('.WorksGrid').addClass('WorksGrid--hover');
      clearInterval(this.outInterval);
      if(!this.isHover) {
        this.isHover = true;
        this.mouseover();
      }
    });

    this.$el.mouseout((e) => {
      $('.WorksGrid').removeClass('WorksGrid--hover');
      this.outInterval = setInterval(() => {
        clearInterval(this.outInterval);
        if(this.isHover) {
          this.isHover = false;
          this.mouseout();
        }
      }, 50);
    });

  }

  mouseover() {
    this.$el.addClass('WorksGridItem--current');

    if($('.WorksGrid').offset()['left'] == this.$el.offset()['left']) {
      this.$el.find('.WorksGridItemInfo').addClass('WorksGridItemInfo--invert');
    } else {
      this.$el.find('.WorksGridItemInfo').removeClass('WorksGridItemInfo--invert');
    }

    if(this.$el.find('.WorksGridItemInfo').hasClass('WorksGridItemInfo--invert')) {
      this.$el
        .find('.WorksGridItemInfo')
        .stop(false)
        .css({'display':'flex', 'opacity':0, 'margin-left': -10})
        .animate({'opacity': 1, 'margin-left': 0}, 800, 'easeInOutExpo');
    } else {
      this.$el
        .find('.WorksGridItemInfo')
        .stop(false)
        .css({'display':'flex', 'opacity':0, 'margin-right': -10})
        .animate({'opacity': 1, 'margin-right': 0}, 800, 'easeInOutExpo');
    }
  }

  mouseout() {
    this.$el.removeClass('WorksGridItem--current');

    if(this.$el.find('.WorksGridItemInfo').hasClass('WorksGridItemInfo--invert')) {
      this.$el
        .find('.WorksGridItemInfo')
        .stop(false)
        .animate({'margin-left': -10, 'opacity':0}, 350, 'easeInOutSine', () => {
          this.$el.find('.WorksGridItemInfo').css('display', 'none');
        });
    } else {
      this.$el
        .find('.WorksGridItemInfo')
        .stop(false)
        .animate({'margin-right': -10, 'opacity':0}, 350, 'easeInOutSine', () => {
          this.$el.find('.WorksGridItemInfo').css('display', 'none');
        });
    }
  }
}

export default WorksGridItemPc;
