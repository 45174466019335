/*globals $ */

class SpSlider {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.direction = 'left';


    this.$slider = $('.SpHeader__slider');//this.$el.find('.SpSliderWrap__inner');

    this.$creditSlider = $('.SpCredit__slider');

    this.isIntroComplete = false;

    this.basePos = 20 - 8;
    this.touchStartPosX = 0;
    this.touchDiffPos = 0;
    this.slidePos = this.basePos;
    this.slideTargetPos = 0;
    this.currentImg = 0;
    this.visible = false;
    this.isMoveHorizontal = false;
    this.isMoveVertival = false;
    this.isMoveFinish = false;

    this.isOverSwipe = false;

    // this.cardNum = this.$el.find('.ContentGrid__col').length;

    this.$btns = [];
    this.isHome = false;

    if($('.Background').length) {
      this.isHome = true;
    }

    if($('body').hasClass('side-sturgeon')) {
      this.currentImg = 1;
      this.direction = 'right';
      this.slidePos = this.slideTargetPos = -window.innerWidth;

      // console.log(this.mainApp.windowWidth);
      this.$slider.css({
        'transform': 'translate3d(-' + window.innerWidth + 'px, 0, 0)'
      });
    }

    /*
    this.$el.find('.SpSliderDots__btn').each((i, e) => {
      this.$btns.push($(e));
    });
    */


    $('body').on('touchstart', (e) => {
      if(!this.isHome || (this.isHome && this.isIntroComplete)) {
        if(!this.isOpenLeftMenu && !this.isOpenRightMenu) {
          this.isMoveHorizontal = false;
          this.isMoveVertical = false;
          this.isMoveFinish = false;

          if(this.isHome) {
            if(this.currentImg == 0) {
              this.spMoveBase = 'left';
              $('.BackgroundContent--left').css('z-index', 1);
              $('.BackgroundContent--right').css({'z-index': 2, 'width': 0});
              this.currentMoveBg = $('.BackgroundContent--right');
              this.moveTargetWidth = this.mainApp.windowWidth;
            } else if(this.currentImg == 1){
              this.spMoveBase = 'right';
              $('.BackgroundContent--left').css({'z-index': 2, 'width': 0});
              $('.BackgroundContent--right').css('z-index', 1);
              this.currentMoveBg = $('.BackgroundContent--left');
              this.moveTargetWidth = 0;
            }

          }

          clearInterval(this.afterSwipeInterval);
          clearInterval(this.updateInterval);

          this.touchStartPosX = this.movePosX = e.originalEvent.touches[0].clientX;
          this.touchStartPosY = this.movePosY = e.originalEvent.touches[0].clientY;

          this.updateInterval = setInterval(() => {
            this.update();
          }, 1000 / 60);
        }
      }

    });

    $('body').on('touchmove', (e) => {
      if(!this.isHome || (this.isHome && this.isIntroComplete)) {
        if(!this.isOpenLeftMenu && !this.isOpenRightMenu) {
          this.movePosX = e.originalEvent.changedTouches[0].clientX;
          this.movePosY = e.originalEvent.changedTouches[0].clientY;
        }
      }
    });

    $('body').on('touchend', () => {
      if(!this.isHome || (this.isHome && this.isIntroComplete)) {
        if(!this.isOpenLeftMenu && !this.isOpenRightMenu) {
          clearInterval(this.updateInterval);

          this.slidePos = this.slidePos + this.lastMoveVal;

          if(
            Math.abs(this.movePosX - this.touchStartPosX) < 5
            && Math.abs(this.movePosY - this.touchStartPosY) < 5
          ) {
            this.isMoveFinish = true;
          clearInterval(this.afterSwipeInterval);
              this.slidePos = this.slideTargetPos;
          } else {
            if(!this.isMoveFinish) {
              this.isMoveFinish = true;

              if(this.lastMoveVal < 0) {
                this.currentImg += 1;
              } else {
                this.currentImg -= 1;
              }


              if(this.currentImg > 1) this.currentImg = 1;
              if(this.currentImg < 0) this.currentImg = 0;


              if(this.currentImg == 0) {
                this.slideTargetPos = 0;
              } else if(this.currentImg == 1) {
                this.slideTargetPos = -this.mainApp.windowWidth;
              }

            }

            if(!this.isOverSwipe) {
              clearInterval(this.afterSwipeInterval);
              this.afterSwipeInterval = setInterval(() => {
                this.updateAfterSwipe();
              }, 1000 / 60);
            } else {
              clearInterval(this.afterSwipeInterval);
              this.slidePos = this.slideTargetPos;
            }
          }
        }
      }

    });



    $('.BtnSpToggleMenu').on('click', (e) => {
      if(!this.isHome || (this.isHome && this.isIntroComplete)) {
        if($(e.currentTarget).attr('data-target') == 'left') {
          if(this.isOpenLeftMenu) {
            this.isOpenLeftMenu = false;
            $('.BtnSpToggleMenu--left').removeClass('BtnToggleMenu--open');
            $('.SpHeaderSide--left .HeaderLogo__type--left').css('opacity', 0);

            $('.MenuPage--left')
              .stop(false)
              .animate({'width': 0}, 500, 'easeOutExpo', () => {
                $('.Menu').css('display', 'none');
              });
          } else {
            this.isOpenLeftMenu = true;
            $('.BtnSpToggleMenu--left').addClass('BtnToggleMenu--open');
            if($('.Menu').css('display') == 'none') {
              $('.Menu').css('display', 'block');
            } else {
              $('.MenuPage--right').css('z-index', 1);
              $('.BtnSpToggleMenu--right').removeClass('BtnToggleMenu--open');
            }

            $('.SpHeaderSide--left .HeaderLogo__type--left').css('opacity', 1);

            this.fadeinMenuItems('left');

            $('.MenuPage--left')
              .stop(false)
              .css({'width': 0, 'z-index': 2})
              .delay(10)
              .animate({'width': '100%'}, 500, 'easeOutExpo', () => {
                if(this.isOpenRightMenu) {
                  this.isOpenRightMenu = false;
                  $('.MenuPage--right').stop(false).css('width', 0);
                }
              });
          }
        } else if($(e.currentTarget).attr('data-target') == 'right') {
          if(this.isOpenRightMenu) {
            this.isOpenRightMenu = false;
            $('.BtnSpToggleMenu--right').removeClass('BtnToggleMenu--open');
            $('.SpHeaderSide--right .HeaderLogo__type--right').css('opacity', 0);

            $('.MenuPage--right')
              .stop(false)
              .animate({'width': 0}, 500, 'easeOutExpo', () => {
                $('.Menu').css('display', 'none');
              });
          } else {
            this.isOpenRightMenu = true;
            $('.BtnSpToggleMenu--right').addClass('BtnToggleMenu--open');
            if($('.Menu').css('display') == 'none') {
              $('.Menu').css('display', 'block');
            } else {
              $('.MenuPage--left').css('z-index', 1);
              $('.BtnSpToggleMenu--left').removeClass('BtnToggleMenu--open');
            }

            $('.SpHeaderSide--right .HeaderLogo__type--right').css('opacity', 1);

            this.fadeinMenuItems('right');

            $('.MenuPage--right')
              .stop(false)
              .css({'width': 0, 'z-index': 2})
              .delay(10)
              .animate({'width': '100%'}, 500, 'easeOutExpo', () => {
                if(this.isOpenLeftMenu) {
                  this.isOpenLeftMenu = false;
                  $('.MenuPage--left').stop(false).css('width', 0);
                }
              });
          }
        }
      }

    });

    this.introValue = 0;
    this.introTarget = -100;
    this.introCount = 0;

    this.homeDirection = 'caviar';



    // if(navigator.userAgent.indexOf('iPhone') > 0 || (navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0)) {
    if($('html').hasClass('ua-mobile')) {
      if(this.isHome) {




        $(window).on('load', () => {
          this.waitInterval = setInterval(() => {
            if($('body').hasClass('onCompleteBackgroundFadein')) {

              clearInterval(this.waitInterval);

              if($('body').hasClass('home-sturgeon')) {

                this.homeDirection = 'sturgeon';
                this.currentImg = 1;
                this.spMoveBase = 'right';
                this.direction = 'right';
                $('.BackgroundContent--left').css({'z-index': 2, 'width': 0});
                $('.BackgroundContent--right').css('z-index', 1);

                this.slidePos = this.slideTargetPos = -window.innerWidth;

                // console.log('ここで適用');
                this.$slider.css({
                  'transform': 'translate3d(-' + window.innerWidth + 'px, 0, 0)'
                });
                this.$creditSlider.css({
                  'transform': 'translate3d(-' + window.innerWidth + 'px, 0, 0)'
                });
              }



              if(this.homeDirection == 'caviar') {
                $('.BackgroundContent--right').css({'width':0, 'opacity':1});
                $('#video_sturgeon')[0].play();
              } else if(this.homeDirection == 'sturgeon') {
                $('.BackgroundContent--left').css({'width':0, 'opacity':1});
                $('#video_caviar')[0].play();
              }

              let moveTarget = 'right';
              if(this.homeDirection == 'sturgeon') {
                moveTarget = 'left';
              }
              // console.log('this.homeDirection', this.homeDirection);

              $('.BackgroundContent--' + moveTarget).animate(
                {'width': 100},
                {
                  'duration': 1000,
                  'easing': 'easeInOutExpo',
                  'step': (now) => {

                    if(this.homeDirection == 'caviar') {
                      this.$slider.css({
                        'transform': 'translate3d(-' + String(now) + 'px, 0, 0)'
                      });
                      this.$creditSlider.css({
                        'transform': 'translate3d(-' + String(now) + 'px, 0, 0)'
                      });
                      $('.SpSwipeGuide').css({
                        'margin-right' : now * 0.2,
                        'opacity': now * 0.01
                      });
                    } else if(this.homeDirection == 'sturgeon') {
                      this.$slider.css({
                        'transform': 'translate3d(-' + String(window.innerWidth - now) + 'px, 0, 0)'
                      });
                      this.$creditSlider.css({
                        'transform': 'translate3d(-' + String(window.innerWidth - now) + 'px, 0, 0)'
                      });
                      $('.SpSwipeGuide').css({
                        'margin-left' : now * 0.2,
                        'opacity': now * 0.01
                      });
                    }



                  },
                  'complete': () => {
                    setTimeout(() => {
                      this.introBack();
                    }, 600);

                  }
                }
              );

              // this.intro();
            }
          }, 1000 / 30);
        });
      }
    }
  }

  introBack() {
    let moveTarget = 'right';
    if(this.homeDirection == 'sturgeon') {
      moveTarget = 'left';
    }

    $('.BackgroundContent--' + moveTarget).animate(
      {'width': 0},
      {
        'duration': 600,
        'easing': 'easeOutExpo',
        'step': (now) => {
          /*
          this.$slider.css({
            'transform': 'translate3d(-' + String(now) + 'px, 0, 0)'
          });
          */

          if(this.homeDirection == 'caviar') {
            this.$slider.css({
              'transform': 'translate3d(-' + String(now) + 'px, 0, 0)'
            });
            this.$creditSlider.css({
              'transform': 'translate3d(-' + String(now) + 'px, 0, 0)'
            });
            $('.SpSwipeGuide').css({
              'margin-right' : now * 0.2,
              'opacity': now * 0.01
            });
          } else if(this.homeDirection == 'sturgeon') {
            this.$slider.css({
              'transform': 'translate3d(-' + String(window.innerWidth - now) + 'px, 0, 0)'
            });
            this.$creditSlider.css({
              'transform': 'translate3d(-' + String(window.innerWidth - now) + 'px, 0, 0)'
            });
            $('.SpSwipeGuide').css({
              'margin-left' : now * 0.2,
              'opacity': now * 0.01
            });
          }



        },
        'complete': () => {
          this.isIntroComplete = true;
        }

      }
    );
  }

  intro() {
    this.introUpdate = setInterval(() => {
      this.introValue += (this.introTarget - this.introValue) / 10;
      if(Math.abs(this.introTarget - this.introValue) < 1) {
        this.introValue = this.introTarget;
        clearInterval(this.introUpdate);

        if(this.introCount == 0) {
          this.introCount++;
          setTimeout(() => {
            this.introTarget = 0;
            this.intro();
          }, 800);
        } else if(this.introCount == 1) {
          $('#video_sturgeon')[0].pause();
        }

      }

      $('.BackgroundContent--right').css({'width':String(Math.abs(this.introValue)) + 'px'});
      this.$slider.css({
        'transform': 'translate3d(' + String(this.introValue) + 'px, 0, 0)'
      });

    });
  }

  update() {
    if(!this.isHome || (this.isHome && this.isIntroComplete)) {
      let moveValX = this.movePosX - this.touchStartPosX;
      let moveValY = this.movePosY - this.touchStartPosY;

      if(Math.abs(moveValY) > Math.abs(moveValX)) {
        // this.isMoveVertical = true;
        this.isMoveFinish = true;
      }

      this.isOverSwipe = false;
      let resultVal = this.slidePos + moveValX;
      if(resultVal > 0) {
        resultVal = 0;
        this.isOverSwipe = true;
      }
      if(resultVal < -this.mainApp.windowWidth) {
        resultVal = -this.mainApp.windowWidth;
        this.isOverSwipe = true;
      }

      if(this.isHome) {
        let normalizeVal = 0;
        if(this.spMoveBase == 'left') {
          normalizeVal = Math.abs(resultVal) / this.moveTargetWidth;
        } else if(this.spMoveBase == 'right') {
          normalizeVal = 1 - Math.abs(resultVal / -this.mainApp.windowWidth);
        }
        this.currentMoveBg.css('width', String(normalizeVal * 100) + '%');
      }


      this.lastMoveVal = moveValX;
      this.$slider.css({
        'transform': 'translate3d(' + String(resultVal) + 'px, 0, 0)'
      });

      this.$creditSlider.css({
        'transform': 'translate3d(' + String(resultVal) + 'px, 0, 0)'
      });
    }


  }

  updateAfterSwipe() {
    this.slidePos += (this.slideTargetPos - this.slidePos) / 3;
    if(Math.abs(this.slideTargetPos - this.slidePos) < 1) {
      clearInterval(this.afterSwipeInterval);
      this.slidePos = this.slideTargetPos;
    }

    if(this.isHome) {
      let normalizeVal;// = this.slidePos / this.slideTargetPos;
      if(this.spMoveBase == 'left') {
        normalizeVal = this.slidePos / this.slideTargetPos;
      } else if(this.spMoveBase == 'right') {
        normalizeVal = 1 - Math.abs(this.slidePos / -this.mainApp.windowWidth);
      }
      this.currentMoveBg.css('width', String(normalizeVal * 100) + '%');
    }

    this.$slider.css({
      'transform': 'translate3d(' + this.slidePos + 'px, 0, 0)'
    });
    this.$creditSlider.css({
      'transform': 'translate3d(' + this.slidePos + 'px, 0, 0)'
    });

  }

  fadeinMenuItems(target) {
    let fadeinRows = [[]];
    let addCnt = 0;
    let prevTopPos = 0;


    $('.MenuPage--' + target).find('.MenuFadeinItem').css('opacity', 0);

    $('.MenuPage--' + target).find('.MenuFadeinItem').each((i, e) => {
      if(addCnt == 0) {
        fadeinRows[0].push($(e));
        prevTopPos = $(e).offset()['top'];
      } else {
        if(prevTopPos == $(e).offset()['top']) {
          fadeinRows[fadeinRows.length - 1].push($(e));
        } else {
          fadeinRows.push([]);
          fadeinRows[fadeinRows.length - 1].push($(e));
          prevTopPos = $(e).offset()['top'];
        }
      }
      addCnt++;
    });

    addCnt = 0;

    for(let i = 0; i < fadeinRows.length; i++) {
      if(target == 'right') {
        fadeinRows[i] = fadeinRows[i].reverse();
      }
      for(let t = 0; t < fadeinRows[i].length; t++) {
        fadeinRows[i][t].delay(200 + addCnt * 40).animate({'opacity':1}, 500);
        addCnt++;
      }
    }
  }
}

export default SpSlider;
