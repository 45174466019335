/*globals $ */

class Menu {
  constructor() {
    this.menuImages = [];
    $('.MenuBg__img').each((i, e) => {
      this.menuImages.push($(e));
    });

    this.spMenuImages = [];
    $('.SpMenuOverlayContent__img').each((i, e) => {
      this.spMenuImages.push($(e));
    });

    if($('html').hasClass('ua-desktop')) {
      this.initMouseEnv();
    } else {
      this.initTouchEnv();
    }
  }

  initMouseEnv() {
    $('.CreditPeople a').mouseover((e) => {
      $(e.currentTarget).parent().parent().addClass('CreditPeople--hover');

      let position = $(e.currentTarget).attr('data-position');

      // $('.PageList, .ComData, .CreditPeople:not(.CreditPeople--hover)').css('opacity', 0.25);
      $('.PageList, .ComData').css('opacity', 0.25);
      $('.CreditPeople:not(.CreditPeople--hover)').addClass('CreditPeople--deactive');
      $('.CreditPeople__position:not([data-position="' + position + '"])').css('opacity', 0.25);


      let targetId = $(e.currentTarget).attr('data-id');
      for(let i = 0; i < this.menuImages.length; i++) {
        if(this.menuImages[i].attr('data-id') == targetId) {
          this.menuImages[i].stop(false).animate({'opacity':1}, 1200, 'easeInOutExpo');
        } else {
          this.menuImages[i].stop(false).css('opacity', 0);
        }
      }
    });

    $('.CreditPeople a').mouseout((e) => {
      $(e.currentTarget).parent().parent().removeClass('CreditPeople--hover');

      let position = $(e.currentTarget).attr('data-position');

      // $('.PageList, .ComData, .CreditPeople:not(.CreditPeople--hover)').css('opacity', 1);
      $('.PageList, .ComData').css('opacity', 1);
      $('.CreditPeople:not(.CreditPeople--hover)').removeClass('CreditPeople--deactive');
      $('.CreditPeople__position:not([data-position="' + position + '"])').css('opacity', 1);

      for(let i = 0; i < this.menuImages.length; i++) {
        if(this.menuImages[i].css('opacity') > 0) {
          this.menuImages[i].stop(false).animate({'opacity':0}, 400, 'easeOutExpo');
        }
      }
    });



    this.isOpenLeftMenu = false;
    this.isOpenRightMenu = false;
    $('.BtnToggleMenu').on('click', (e) => {
      if($(e.currentTarget).attr('data-target') == 'left') {
        if(this.isOpenLeftMenu) {
          this.isOpenLeftMenu = false;
          $('.Header--left').removeClass('Header--open');
          $('.BtnToggleMenu--left').removeClass('BtnToggleMenu--open');
          $('.MenuPage--left')
            .stop(false)
            .animate({'width': 0}, 500, 'easeOutExpo', () => {
              $('.Menu').css('display', 'none');
            });
        } else {
          this.isOpenLeftMenu = true;
          $('.Header--left').addClass('Header--open');
          $('.BtnToggleMenu--left').addClass('BtnToggleMenu--open');
          if($('.Menu').css('display') == 'none') {
            $('.Menu').css('display', 'block');
          } else {
            $('.Header--right').removeClass('Header--open');
            $('.MenuPage--right').css('z-index', 1);
            $('.BtnToggleMenu--right').removeClass('BtnToggleMenu--open');
          }

          // this.fadeinMenuItems('left');

          $('.MenuPage--left')
            .stop(false)
            .css({'width': 0, 'z-index': 2})
            .delay(10)
            .animate({'width': '100%'}, 500, 'easeOutExpo', () => {
              if(this.isOpenRightMenu) {
                this.isOpenRightMenu = false;
                $('.MenuPage--right').stop(false).css('width', 0);
              }
            });
        }
      } else if($(e.currentTarget).attr('data-target') == 'right') {
        if(this.isOpenRightMenu) {
          this.isOpenRightMenu = false;
          $('.Header--right').removeClass('Header--open');
          $('.BtnToggleMenu--right').removeClass('BtnToggleMenu--open');
          $('.MenuPage--right')
            .stop(false)
            .animate({'width': 0}, 500, 'easeOutExpo', () => {
              $('.Menu').css('display', 'none');
            });
        } else {
          this.isOpenRightMenu = true;
          $('.Header--right').addClass('Header--open');
          $('.BtnToggleMenu--right').addClass('BtnToggleMenu--open');
          if($('.Menu').css('display') == 'none') {
            $('.Menu').css('display', 'block');
          } else {
            $('.Header--left').removeClass('Header--open');
            $('.MenuPage--left').css('z-index', 1);
            $('.BtnToggleMenu--left').removeClass('BtnToggleMenu--open');
          }

          // this.fadeinMenuItems('right');

          $('.MenuPage--right')
            .stop(false)
            .css({'width': 0, 'z-index': 2})
            .delay(10)
            .animate({'width': '100%'}, 500, 'easeOutExpo', () => {
              if(this.isOpenLeftMenu) {
                this.isOpenLeftMenu = false;
                $('.MenuPage--left').stop(false).css('width', 0);
              }
            });
        }
      }
    });
  }

  fadeinMenuItems(target) {
    let fadeinRows = [[]];
    let addCnt = 0;
    let prevTopPos = 0;


    $('.MenuPage--' + target).find('.MenuFadeinItem').css('opacity', 0);

    $('.MenuPage--' + target).find('.MenuFadeinItem').each((i, e) => {
      if(addCnt == 0) {
        fadeinRows[0].push($(e));
        prevTopPos = $(e).offset()['top'];
      } else {
        if(prevTopPos == $(e).offset()['top']) {
          fadeinRows[fadeinRows.length - 1].push($(e));
        } else {
          fadeinRows.push([]);
          fadeinRows[fadeinRows.length - 1].push($(e));
          prevTopPos = $(e).offset()['top'];
        }
      }
      addCnt++;
    });

    addCnt = 0;

    for(let i = 0; i < fadeinRows.length; i++) {
      if(target == 'right') {
        fadeinRows[i] = fadeinRows[i].reverse();
      }
      for(let t = 0; t < fadeinRows[i].length; t++) {
        // console.log('fadein menu ', addCnt);
        fadeinRows[i][t].delay(200 + addCnt * 40).animate({'opacity':1}, 500);
        addCnt++;
      }
    }
  }

  initTouchEnv() {
    this.currentId = '';
    $('.CreditPeople a').on('click', (e) => {

      // console.log('MenuPage--left width', $('.MenuPage--left').width());
      // console.log('MenuPage--right width', $('.MenuPage--right').width());

      if($('.MenuPage--right').width() > 0) {
        $('.SpMenuOverlayContent, .SpMenuOverlayContent__img').css({
          'left': 'auto',
          'right': 0
        });
      }

      // console.log('this.isOpenLeftMenu', this.isOpenLeftMenu);
      // console.log('this.isOpenRightMenu', this.isOpenRightMenu);

      let targetId = $(e.currentTarget).attr('data-id');
      for(let i = 0; i < this.spMenuImages.length; i++) {
        if(this.spMenuImages[i].attr('data-id') == targetId) {
          this.spMenuImages[i].stop(false).animate({'opacity':1}, 1200, 'easeInOutExpo');
        }/* else {
          this.menuImages[i].stop(false).css('opacity', 0);
        }*/

      }

      $('.SpMenuOverlay').css('display', 'block');
      $('.SpMenuOverlayContent').animate({'width':'100%'}, 1000, 'easeInOutExpo', () => {
        setTimeout(() => {
          location.href = $(e.currentTarget).attr('href');
        }, 500);
      });



      /*
      this.currentId = $(e.currentTarget).attr('data-id');
      $(e.currentTarget).parent().addClass('CreditPeople--hover');

      for(let i = 0; i < this.menuImages.length; i++) {
        if(this.menuImages[i].attr('data-id') == this.currentId) {
          this.menuImages[i].stop(false).animate({'opacity':1}, 1000, 'easeOutExpo');

          if($('body').hasClass('home')) {
            $('.SpOverlay').css({'height': window.innerHeight, 'opacity':0, 'width': '100%'}).delay(400).animate({'opacity': 1}, 900, 'easeInOutExpo', () => {
              location.href = $(e.currentTarget).attr('href');
            });
          } else {
            $('.SpOverlay').css({'height': window.innerHeight - 70, 'opacity':0, 'width': '100%'}).delay(400).animate({'opacity': 1}, 900, 'easeInOutExpo', () => {
              location.href = $(e.currentTarget).attr('href');
            });
          }

        } else {
          this.menuImages[i].stop(false).css('opacity', 0);
        }
      }
      */

      return false;
    });

  }
}

export default Menu;
