
// import "../scss/style.scss";

/*globals Pace */

import jQuery from 'jquery';
const $ = jQuery;

window.jQuery = $;
window.$ = $;

import 'cssuseragent';
import 'jquery.easing';

import socialButton from './module/Social';
import Works from './module/Works';
import SpSlider from './module/SpSlider';
import Background from './module/Background';
import WorksGridItemPc from './module/WorksGridItemPc';
import Menu from './module/Menu';

import ImgLazyLoad from './module/img-lazy-load';

class PageDirector {
  constructor(mainApp) {
    this.mainApp = mainApp;
  }

  onWindowScroll(scrollTop) {
    let basePos = $('.DirectorWorks').offset()['top'];
    if(this.mainApp.viewMode == 'sp') {
      basePos -= 40;
    }
    if(basePos <= scrollTop ) {
      $('.WorksTags').css('transform', 'translate3d(0, ' + String(scrollTop - basePos) + 'px, 0)');
    } else {
      $('.WorksTags').css('transform', 'translate3d(0, 0, 0)');
    }
    // console.log(scrollTop);
    // console.log($('.DirectorWorks').offset()['top']);
  }
}

class MainApp {
  constructor() {

    this.viewMode       = 'pc';
    this.isTablet       = false;
    this.isHome = false;
    this.onLoadComplete = false;

    if($('body').hasClass('home')) {
      this.isHome = true;
      Pace.on('done', () => {

        if(location.hash.length <= 1) {
          this.init();
        }

      });

    } else {
      /*
      $('.Header--left').mouseover(() => {
        $('.Header--left').addClass('Header--current');
        $('.Header--right').removeClass('Header--current');
      });
      $('.Header--right').mouseover(() => {
        $('.Header--left').removeClass('Header--current');
        $('.Header--right').addClass('Header--current');
      });
      */
      if($('html').hasClass('ua-desktop')) {
        $('.HeaderLogo--caviar, .BtnToggleMenu--left').on('mouseover', () => {
          $('.Header--left .HeaderLogo, .Header--left .BtnToggleMenu, .Header--left .HeaderCopyright').css('opacity', 1);
        });
        $('.HeaderLogo--sturgeon, .BtnToggleMenu--right').on('mouseover', () => {
          $('.Header--right .HeaderLogo, .Header--right .BtnToggleMenu, .Header--right .HeaderCopyright').css('opacity', 1);
        });

        $('.HeaderLogo--caviar, .BtnToggleMenu--left').on('mouseout', () => {
          $('.Header--left .HeaderLogo, .Header--left .BtnToggleMenu, .Header--left .HeaderCopyright').css('opacity', 0.5);
        });
        $('.HeaderLogo--sturgeon, .BtnToggleMenu--right').on('mouseout', () => {
          $('.Header--right .HeaderLogo, .Header--right .BtnToggleMenu, .Header--right .HeaderCopyright').css('opacity', 0.5);
        });
      }

    }

    if($('body').hasClass('page-template-page-director')) {
      this.pageApp = new PageDirector(this);
    }


    if(Number($('.check-res').width()) > 900) {
      this.viewMode = 'pc';
    } else {
      this.viewMode = 'sp';
    }

    this.spSlider = new SpSlider(this);

    if($('html').hasClass('ua-desktop')) {
      $('.FooterInfoListItem__btn[data-type="tel"]').on('click', () => {
        return false;
      });
    }

    if($('.WorksGrid').length) {
      new Works(this);
    }

    // シェアボタン
    $('.js-btn-share').on('click', (e) => {
      let shareText = document.title;
      if(!$('body').hasClass('home')) {
        // shareText = shareText.replace('NIPPONIA - なつかしくて、あたらしい、日本の暮らしをつくる', 'NIPPONIA');
      }

      socialButton($(e.currentTarget).attr('data-type'), location.href, shareText);
      return false;
    });

    this.$window        = $(window);

    this.imgLazyLoad = new ImgLazyLoad(this);

    $('.js-btn-pagetop').on('click', () => {
      $('html, body').animate({'scrollTop': 0}, 1000, 'easeInOutExpo');
      return false;
    });

    new Menu();


    if(!$('html').hasClass('ua-ios') && !$('html').hasClass('ua-android')) {
      $('.WorksGridItem:not(.WorksGridItem--blank)').each((i, e) => {
        new WorksGridItemPc($(e));
      });
    }


    $('.NoteContent').find('a').each((i, e) => {
      let _text = '<a href="' + $(e).attr('href') + '"><span>' + $(e).text() + '</span></a>';
      if($(e).attr('target') != undefined) {
        _text = '<a href="' + $(e).attr('href') + '" target="' + $(e).attr('target') + '"><span>' + $(e).text() + '</span></a>';
      }

      $(e).replaceWith(_text);
    });

    $('.NoteContent').find('iframe').each((i, e) => {
      let iframe = $(e);
      let ytContainer = $('<span class="embed-container"></span>');
      $(e).replaceWith(ytContainer)
      ytContainer.append(iframe);
    });

    this.isShowPagetop = false;

    if($('body').hasClass('page-template-page-works') && $('html').hasClass('ua-mobile')) {

      $('.wrapper > .WorksWidth').css('padding-top', $('.WorksTagsPosition').height());
    }

    if($('.Background').length) {
      this.background = new Background(this);
    }


    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      })
      .on('scroll', ()=>{
        this.onWindowScroll();
      });
    this.onWindowResize();

    /*
    if($('.slick-slider').length) {
      new SlickSlider(this);
    }
    */

    // if(this.isHome && this.viewMode == 'sp') {
    if(this.isHome && $('html').hasClass('ua-mobile')) {
      $('.Firstview').css('height', window.innerHeight);
    }
  }

  // Paceの読み込み完了後
  init() {
    this.onLoadComplete = true;
    if($('html').hasClass('ua-desktop')) {
      this.prevMousePos = 0;
      this.currentMousePos = 0;
      this.moveVal = 0;

      $('body').on('mousemove', (e) => {
        if(this.prevMousePos == 0) {
          this.prevMousePos = this.currentMousePos = e.pageX;
        } else {
          this.currentMousePos = e.pageX;
          this.moveVal += (this.currentMousePos - this.prevMousePos);

          if(Math.abs(this.moveVal) > 300) {

            if(this.moveVal < 0) {
              this.introFinish('caviar');
            } else {
              this.introFinish('sturgeon');
            }


          }
          this.prevMousePos = this.currentMousePos;
        }
      });
    } else {
      $('.FirstviewHotspotRow__col').on('click', (e) => {
        $('.FirstviewHotspot').css('display', 'none');
        if($(e.currentTarget).attr('data-side') == 'caviar') {
          this.introFinish('caviar');
        } else if($(e.currentTarget).attr('data-side') == 'sturgeon') {
          this.introFinish('sturgeon');
          // $('.SpSwipeGuide').addClass('SpSwipeGuide--invert');
        }
      });
    }
  }

  introFinish(target) {
    if(target == 'sturgeon') {
      $('.SpSwipeGuide').addClass('SpSwipeGuide--invert');
    }
    $('body').addClass('home-' + target);
    $('body').off('mousemove');

    if(target == 'caviar') {
      this.background.init('caviar');
    } else if(target == 'sturgeon') {
      $('.Header--left').removeClass('Header--current');
      $('.Header--right').addClass('Header--current');
      this.background.init('sturgeon');

      $('.SpHeader__slider').css({
        'transform': 'translate3d(-' + window.innerWidth + 'px, 0, 0)'
      });
      $('.SpCredit__slider').css({
        'transform': 'translate3d(-' + window.innerWidth + 'px, 0, 0)'
      });
    }

    $('.Background').css('opacity', 1);
    $('.Firstview').delay(30).animate({'opacity':0}, 500, 'easeInSine', () => {
      $('.Firstview').remove();
    });

    $('.SpHeader').css('opacity', 1);
    $('.Header').removeClass('Header--preload');

  }

  onWindowScroll() {
    this.scrollTop = this.$window.scrollTop();

    if(this.windowHeight < $('.wrapper').outerHeight(true)) {
      /*
      if(this.scrollTop + this.windowHeight >= $('.wrapper').outerHeight(true) - 30) {
        if(!this.isShowPagetop) {
          this.isShowPagetop = true;
          $('.Pagetop').stop(false).css({'display': 'block', 'opacity':0}).delay(10).animate({'opacity':1}, 400, 'easeOutExpo');
        }
      } else {
        if(this.isShowPagetop) {
          this.isShowPagetop = false;
          $('.Pagetop').stop(false).animate({'opacity':0}, 400, 'easeOutExpo', () => {
            $('.Pagetop').css('display', 'none');
          });
        }
      }
      */
      if(this.scrollTop > 50) {
        /*
        if(!this.isShowPagetop) {
          this.isShowPagetop = true;
          $('.Pagetop').stop(false).css({'display': 'block', 'opacity':0}).delay(10).animate({'opacity':1}, 400, 'easeOutExpo');
        }
        */
        clearInterval(this.scrollTopTimer);

        if(this.isShowPagetop) {
          this.isShowPagetop = false;
          $('.Pagetop').stop(false).css('display', 'none');
        }

        this.scrollTopTimer = setInterval(() => {
          clearInterval(this.scrollTopTimer);
          if(this.scrollTop > 50 && !this.isShowPagetop) {
            this.isShowPagetop = true;
            $('.Pagetop').stop(false).css({'display': 'block', 'opacity':0}).delay(10).animate({'opacity':1}, 400, 'easeOutExpo');
          }
        }, 500);

      } else {
        clearInterval(this.scrollTopTimer);
        if(this.isShowPagetop) {
          this.isShowPagetop = false;
          $('.Pagetop').stop(false).animate({'opacity':0}, 400, 'easeOutExpo', () => {
            $('.Pagetop').css('display', 'none');
          });
        }
      }
    } else {
      clearInterval(this.scrollTopTimer);
      if(this.isShowPagetop) {
        this.isShowPagetop = false;
        $('.Pagetop').stop(false).animate({'opacity':0}, 400, 'easeOutExpo', () => {
          $('.Pagetop').css('display', 'none');
        });
      }
    }

    if(this.pageApp) {
      this.pageApp.onWindowScroll(this.scrollTop);
    }

    this.imgLazyLoad.update(this.scrollTop, this.windowHeight);
  }

  onWindowResize() {
    this.windowWidth = this.$window.width();
    this.windowHeight = this.$window.height();


    if(Number($('.check-res').width()) > 900) {
      this.viewMode = 'pc';
    } else {
      this.viewMode = 'sp';
    }

    if(this.viewMode == 'sp') {
      this.windowHeight = window.innerHeight;
    }
    /*
    if(Number($('.check-tablet').width()) > 960) {
      this.isTablet = false;
    } else {
      this.isTablet = true;
    }
    */

    if(this.viewMode == 'sp') {
      if($('.wrapper').outerHeight(true) < window.innerHeight) {
        $('.wrapper').css('height', window.innerHeight);
      }
    }

    this.onWindowScroll();
  }

  onLoad() {
    if(this.background != undefined) {
      // this.background.init();
    }

    if(this.isHome) {
      if(location.hash.length > 1 && (location.hash == '#caviar' || location.hash == '#sturgeon')) {
        if(location.hash == '#caviar') {
          this.introFinish('caviar');
        } else if(location.hash == '#sturgeon') {
          this.introFinish('sturgeon');
        }
      } else {
        this.loadInterval = setInterval(() => {
          if(this.onLoadComplete) {
            clearInterval(this.loadInterval);
          }
        }, 1000 / 30);
      }

    }

  }

}

let mainApp = undefined;



$('document').ready(() => {
  /*
  $.ajax({'type':'get','url': window.url_template + '/assets/images/symbol-defs.svg'}).done((data) => {
    let svg = $(data).find('svg');
    $('body').append(svg);
  });
  */

  mainApp = new MainApp();

});

$(window).on('load', () => {

  $('body').addClass('onload');
  mainApp.onLoad();

});


